class HttpClient {
  async get(url: string) {
    url = this.withBase(url);

    const response = await fetch(url);

    return await response.json();
  }

  async post(url: string = "", data = {}, headers = {}) {
    url = this.withBase(url);

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });

    return await response.json();
  }

  private withBase = (url: string) => `${process.env.REACT_APP_HOST_URL}/api/v1/${url}`
}

export const httpClient = new HttpClient();
