import React, { useCallback, useEffect, useState } from "react";
import { httpClient } from "../../common/httpClient";
import { IntegrationType } from "../suppliers";
import ShopifyConfiguration from "./shopify.configuration";
import { SupplierCommonConfig } from "../suppliers-common/supplier.common.config";

interface Props{
    onNextPage: () => void
    onTokenReceived: (token: {token: string, type: any, category: any}) => void
    supplier: SupplierCommonConfig,
    phase: "init" | "complete"
}

const openAuthUrl = (url: string) => window.open(url, '_blank', 'location=yes,fullscreen=yes,scrollbars=yes,status=yes');

export const ShopConfigurationPage = ({onNextPage, onTokenReceived, supplier, phase}: Props) => {

    const [isReady, setIsReady] = useState<boolean>(false);

    const mergeSupplierData = (request: Partial<SupplierCommonConfig>): Partial<SupplierCommonConfig> => {
        let {integrationType, linkToken, ...supplierRequest}: Partial<SupplierCommonConfig> = supplier

        supplierRequest = {integrationType, ...supplierRequest, ...request}

        return supplierRequest;
    }

    const onSubmit = async (request: Partial<SupplierCommonConfig>) => {
        const supplierRequest = mergeSupplierData(request);

        const response = await httpClient.post("connection/initiate", supplierRequest, {
            'X-LINK-TOKEN': supplier.linkToken
        });

        onTokenReceived({token: response.linkToken, type: response.authenticationProfile.integrationType, category: response.authenticationProfile.category})

        openAuthUrl(response.authenticationProfile.authenticationUrl)

        onNextPage();
    }

    const initiate = useCallback(async () => {
        if(supplier.integrationType === null)
            return;

        if(phase == "complete"){
            await onSubmit({});
            return;
        }

        switch(supplier.integrationType){
            case IntegrationType.Printify:
                await onSubmit({});
                break;
            case IntegrationType.Printful:
                await onSubmit({});
                break;
            case IntegrationType.Etsy:
                await onSubmit({});
                break;
            case IntegrationType.TikTok:
                await onSubmit({});
                break;
            case IntegrationType.Amazon:
                await onSubmit({});
                break;
            default:
                setIsReady(true);
                break;
            }
    }, [])

    useEffect(() => {
        initiate();
    }, [initiate])

    return (
        <>
            {
                isReady &&
                    <>
                       {supplier.integrationType == IntegrationType.Shopify && <ShopifyConfiguration onSubmit={async (request) => {
                            await onSubmit(request)
                       }}/>}
                    </>
            }
        </>
    )
};