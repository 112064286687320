import React from "react";
import { Box, Button, Card, CardActions, CardContent, ImageList, ImageListItem, TextField, Typography } from "@mui/material";
import "./index.scss";

interface Props{
    onNextPage: (integrationType: IntegrationType) => void
}

type IconMediaType = "svg" | "png";

class Integration{
    constructor(public type: IntegrationType, public category: IntegrationCategory, public isActive: boolean = true, public iconMediaType: IconMediaType = "png") {
        
    }
}

export enum IntegrationType{
    Shopify = "Shopify",
    Printify = "Printify",
    Printful = "Printful",
    Etsy = "Etsy",
    TikTok = "TikTok",
    Amazon = "Amazon"
}

export enum IntegrationCategory
{
    Marketplace = 0,
    PrintOnDemand = 1
}

const integrations = [
    new Integration(IntegrationType.Shopify, IntegrationCategory.Marketplace),
    new Integration(IntegrationType.Printify, IntegrationCategory.PrintOnDemand),
    new Integration(IntegrationType.Printful, IntegrationCategory.PrintOnDemand),
    new Integration(IntegrationType.Etsy, IntegrationCategory.Marketplace),
    new Integration(IntegrationType.TikTok, IntegrationCategory.Marketplace, true, "svg"),
    new Integration(IntegrationType.Amazon, IntegrationCategory.Marketplace),
]

export const SuppliersConfigurationPage = ({onNextPage}: Props) => {

    const onSupplierClick = (integrationType: IntegrationType) => {
        onNextPage(integrationType);
    }

    return (
        <div className="suppliers-container">
            <ImageList cols={3} rowHeight={128} >
                {integrations.map((item, i) => <IntegrationView key={i} onClick={onSupplierClick} integration={item}/>)}
            </ImageList>
            <Typography className="suppliers-container--footer-text">The term "Etsy" is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.</Typography>
        </div>
    )
};

interface IntegrationViewProps{
    integration: Integration,
    onClick: (integrationType: IntegrationType) => void
}

const IntegrationView = ({integration, onClick}: IntegrationViewProps) => {
    const url = `/content/img/${integration.type.toLocaleLowerCase()}.${integration.iconMediaType}`;

    const onItemSelect = () => {
        if(!integration.isActive)
            return

        onClick(integration.type);
    }

    const integrationType = integration.type.toString();

    return (
        <div id={`integration-${integrationType.toLocaleLowerCase()}`} onClick={() => onItemSelect()}>
            <ImageListItem key={integration.type}>
                <Box sx={{ minWidth: 32 , height: "100%"}}>
                    <Card variant="outlined">
                    <CardContent className="supplier-container">
                        <div className="supplier-container--image">
                            <img
                                srcSet={url}
                                src={url}
                                alt={integrationType}
                                loading="lazy"
                            />
                        </div>
                        <Typography variant="h6">{integrationType}</Typography>
                        {!integration.isActive && <Typography className="supplier-container--footer-text">Coming soon...</Typography>}
                    </CardContent>
                </Card>
                </Box>
            </ImageListItem>
        </div>
    )
}