import React from "react";
import { Button, CardActions, TextField, Typography } from "@mui/material";
import { SupplierCommonConfig } from "./supplier.common.config";

interface Props {
    onSubmit: (request: Partial<SupplierCommonConfig>) => void;
}

const CommonConfiguration: React.FC<Props> = (props: Props) => {
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const connectionName = (e.target as any).elements.connectionName.value;

    props.onSubmit({name: connectionName});
  };

  return (
    <div>
      <Typography>Please fill out connection name</Typography>
      <br></br>
      <div>
        <form onSubmit={onSubmit}>
          <TextField
            id="connectionName"
            size="small"
            required
            label="Connection name"
            variant="outlined"
          />

          <CardActions className="actions">
            <Button id={"submit-integration-config"} variant="outlined" size="small" type="submit">
              Submit
            </Button>
          </CardActions>
        </form>
      </div>
    </div>
  );
};

export default CommonConfiguration;
