import { Button, Typography } from "@mui/material";
import React from "react";

interface Props{
    onComplete: () => void
}

export const FinishPage = ({onComplete}: Props) => {

    return (
        <div>
            <Typography>Nicely done!</Typography>
            <Typography>You successfully completed integration process</Typography>
            <br></br>
            <Button id={"finish-integration-process"} size="small" variant="outlined" onClick={onComplete}>Finish</Button>
        </div>
    )
}