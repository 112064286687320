import React from "react";
import "./style.scss";
import { Button, CardActions, Typography } from "@mui/material";


interface Props{
    onNextPage: () => void
}

export const StartPage = ({onNextPage}: Props) => {
    return (
        <>
            <Typography variant="h5">Welcome!</Typography>
            <br></br>
            <Typography>On next steps you'll be able to configure integrations</Typography>
            <CardActions className="actions">
                <Button id={"next-page"} size="small" variant="outlined" onClick={onNextPage}>Go Next</Button>
            </CardActions>
        </>
    )
};