import React from "react";
import { Button, CardActions, TextField, Typography } from "@mui/material";
import { SupplierCommonConfig } from "../suppliers-common/supplier.common.config";

interface Props {
    onSubmit: (request: Partial<SupplierCommonConfig>) => Promise<void>;
}

const ShopifyConfiguration = (props: Props) => {
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const shopName = (e.target as any).elements.shopName.value;

    await props.onSubmit({ slug: shopName });
  };

  return (
    <div>
      <Typography>Please fill shop name</Typography>
      <br></br>
      <div>
        <form onSubmit={onSubmit}>
          <TextField
            id="shopName"
            size="small"
            label="Shop name"
            variant="outlined"
          />

          <CardActions className="actions">
            <Button id={"submit-integration-config"} variant="outlined" size="small" type="submit">
              Submit
            </Button>
          </CardActions>
        </form>
      </div>
    </div>
  );
};

export default ShopifyConfiguration;
