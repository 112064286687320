import React, { useEffect, useState } from "react";
import { httpClient } from "../../common/httpClient";
import { CircularProgress, Typography } from "@mui/material";
import "./index.scss"

interface Props{
    onNextPage: (accessToken: string) => void
    token: {token: string | null, category: any} | null
}

type Progress = "." | ".." | "...";

const progressMap: Map<Progress, Progress> = new Map([
    [".", ".."],
    ["..", "..."],
    ["...", "."]
]);

export const CompletionMonitor = ({token, onNextPage}: Props) => {

    //const [progress, setProgress] = useState<Progress>(".");
    const [error, setError] = useState<string>();

    useEffect(() => {
        if(token === null)
            return () => {};

        const interval = setInterval(async () => {

            //setProgress(progressMap.get(progress) as Progress);
            
            const response = await httpClient.get(`connection/established/${token.token}`)

            if(response.length > 0){
                const error = response.find((e: any) => e);

                setError(error.message);

                clearInterval(interval);
            }

            if(response.established){
                onNextPage(response.publicToken);

                clearInterval(interval);
            }

        }, 2_000);

        return () => { clearInterval(interval) }
    }, [token]);

    /*
    useEffect(() => {
        if(token === null)
            return () => {}

        const interval = setInterval(async () => {

            setProgress(progressMap.get(progress) as Progress);
            
            const response = await httpClient.get(`connection/established/${token.token}`)

            if(response.length > 0){
                const error = response.find((e: any) => e);

                setError(error.message);

                clearInterval(interval);
            }

            if(response.established){
                onNextPage(response.publicToken);

                clearInterval(interval);
            }

        }, 2_000);

        return () => { clearInterval(interval) }
    }, [token, progress])*/

    return (
        <div className="awaiter">
            {
                error == null 
                    ?
                        (
                            <>
                                <Typography>Waiting for completing authorization process</Typography>
                                <div className="awaiter-container">
                                    <CircularProgress />
                                </div>
                            </>
                        )
                    : (
                        <>
                            <Typography>
                                There is an error has been acquired:
                            </Typography>
                            <Typography>
                                {error}
                            </Typography>
                        </>)
            }
            
        </div>
    )

}