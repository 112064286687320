import React, { useEffect, useState } from 'react';
import './App.scss';
import { Wizard, WizardPage } from './components/wizard';
import { Box, Button, Card, CardActions, CardContent, Modal, Typography } from "@mui/material";
import { SupplierCommonConfig } from './components/suppliers-common/supplier.common.config';

export interface WizardState{
  page: WizardPage,
  supplier?: SupplierCommonConfig | null
}

function App() {
  const [wizard, updateWizard] = useState<WizardState>({page: "start", supplier: null});
  const [token, setToken] = useState<{token: string, type: any, category: any} | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);

  const closeWidget = () => {
    (window as any).top.postMessage('widget:close', '*')

    updateWizard({...wizard, page: "start"});
  };
  
  const widgetComplete = (token: {token: string, type: any, category: any} | null) => {
    const message: any = {
      type: "widget:complete",
      token: token
    };

    (window as any).top.postMessage(JSON.stringify(message), '*')

    updateWizard({...wizard, page: "start"});
  };

  const updateWizardWithLog = (wizardState: WizardState, target: any) => {
    updateWizard({...wizardState});
  }

  window.onmessage = (event: any) => {
    try {
      var data = JSON.parse(event.data);
      if (data.type == "widget:init") {
        setIsReady(true);
        updateWizardWithLog({...wizard, supplier: {...wizard.supplier, linkToken: data.linkToken}}, 'startpage')
      }
    } catch {}
  }

  useEffect(() => {
    const message: any = {
      type: "widget:ready"
    };

    (window as any).top.postMessage(JSON.stringify(message), '*')
  }, []);

  return (
    <div className="widget">
      <div className="widget-container">
        <Box sx={{ minWidth: 275 , height: "100%"}}>
          <Card variant="outlined">
            <CardActions>
              <Button size="small" variant="outlined" onClick={closeWidget}>Close widget</Button>
            </CardActions>
            <CardContent>
              {
                isReady && 
                  <Wizard
                    className="widget-container--body"
                    token={token}
                    setToken={setToken} 
                    wizard={wizard}
                    updateWizard={updateWizardWithLog}
                    onComplete={() => widgetComplete(token)}
                  />
              }
              {
                !isReady && (<span>Loading...</span>)
              }
              </CardContent>
          </Card>
        </Box>
      </div>
    </div>
  );
}

export default App;
