import React, { useEffect, useState } from "react";
import { StartPage } from "../startPage";
import { ShopConfigurationPage } from "../shop-configuration";
import { CompletionMonitor } from "../completion-awaiter";
import { FinishPage } from "../finish";
import { SuppliersConfigurationPage } from "../suppliers";
import { WizardState } from "../../App";
import CommonConfiguration from "../suppliers-common";
import { httpClient } from "../../common/httpClient";

export type WizardPage =
  | "start"
  | "common-configuration"
  | "suppliers"
  | "shop-configuration"
  | "awaiting-completion"
  | "finish";

interface Props {
  wizard: WizardState;
  updateWizard: (wizard: WizardState, target: any) => void;
  token: { token: string; type: any; category: any } | null;
  setToken: (token: { token: string; type: any; category: any }) => void;
  onComplete: () => void;
  className?: string;
}

export const Wizard = ({
  wizard,
  updateWizard,
  token,
  setToken,
  onComplete,
  className,
}: Props) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [phase, setPhase] = useState<"init" | "complete">("init");

  const renderPage = (page: WizardPage) => {
    switch (page) {
      case "start":
        return (
          <StartPage
            onNextPage={() =>
              updateWizard(
                { ...wizard, page: "common-configuration" },
                "startpage"
              )
            }
          />
        );
      case "common-configuration":
        return (
          <CommonConfiguration
            onSubmit={(supplier) => {
              updateWizard(
                {
                  ...wizard,
                  supplier: { ...wizard.supplier, ...supplier },
                  page: "suppliers",
                },
                "common-configuration"
              );
            }}
          />
        );
      case "suppliers":
        return (
          <SuppliersConfigurationPage
            onNextPage={(integrationType) => {
              updateWizard(
                {
                  ...wizard,
                  page: "shop-configuration",
                  supplier: { ...wizard.supplier, integrationType: integrationType },
                },
                "suppliers"
              );
            }}
          />
        );
      case "shop-configuration":
        return (
          <ShopConfigurationPage
            supplier={wizard.supplier!}
            onNextPage={() =>
              updateWizard(
                { ...wizard, page: "awaiting-completion" },
                "shop-configuration"
              )
            }
            onTokenReceived={setToken}
            phase={phase}
          />
        );
      case "awaiting-completion":
        return (
          <CompletionMonitor
            token={token}
            onNextPage={(publicToken) => {
              updateWizard(
                { ...wizard, page: "finish" },
                "awaiting-completion"
              );
              setToken({
                token: publicToken,
                type: token!.type,
                category: token!.category,
              });
            }}
          />
        );
      case "finish":
        return <FinishPage onComplete={onComplete} />;
      default:
        return <></>;
    }
  };

  const checkConnectionVerificationAsync = async () => {
    const linkToken = wizard.supplier?.linkToken;
    const connectionReadyResponse = await httpClient.get(
      `connection/verify-phase/${linkToken}`
    );

    if (connectionReadyResponse.isSuccess && connectionReadyResponse.integrationInitialized) {
      updateWizard(
        {
          ...wizard, 
          page: "shop-configuration",
          supplier: {
            ...wizard.supplier,
            integrationType: connectionReadyResponse.integrationType,
          },
        },
        "suppliers"
      );
      setPhase("complete");
      setIsReady(true);

      return;
    }

    setPhase("init");
    setIsReady(true);
  };

  useEffect(() => {
    checkConnectionVerificationAsync();
  }, []);

  return <div className={className}>{isReady && renderPage(wizard.page)}</div>;
};
